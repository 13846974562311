<template>
  <div class="d-flex align-items-end justify-content-center">
    <span
      v-for="(type, index) in networkTypes"
      :key="index"
      class="signal-bar"
      :class="{
        fill: !(currentIndex < 0) && index >= currentIndex,
      }"
      :style="{ height: getHeight(index) }"
    >
    </span>
    <!-- <button @click="getSpeed">Check internet speed</button> -->
  </div>
</template>
<script>
export default {
  props: ['currentNetworkType'],
  data() {
    return {
      networkTypes: ['4g', '3g', '2g', 'slow-2g'],
      log: console.log,
    };
  },
  computed: {
    currentIndex() {
      if (this.currentNetworkType)
        return this.networkTypes.findIndex(
          (type) => type === this.currentNetworkType
        );
      else return -1;
    },
  },
  methods: {
    getHeight(index) {
      switch (index) {
        case 0:
          return '35px';
        case 1:
          return '25px';
        case 2:
          return '15px';
        case 3:
          return '10px';
        default:
          return '10px';
      }
    },
  },
};
</script>
<style>
.signal-bar {
  width: 4px;
  background-color: #f0f0f0;
  margin: 0 1.3px;
}
.fill {
  background-color: #1890ff !important;
}
</style>
