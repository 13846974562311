<template>
  <a-modal
    v-model:visible="showUploadModal"
    title="Upload Video(s)"
    width="100%"
    wrap-class-name="full-modal"
    :footer="null"
    destroyOnClose
    id="upload-video-modal"
  >
    <upload-video
      @closeModal="showUploadModal = false"
    ></upload-video>
  </a-modal>
  
  <a-modal
    v-model:visible="showVideo"
    :title="null"
    :footer="null"
    destroyOnClose
    centered
    width="800px"
    id="show-video-modal"
  >
    <iframe
      v-if="showVideo"
      width="100%"
      height="420"
      class="mt-4"
      src="https://www.youtube.com/embed/eQgIatSxkUI?autoplay=1"
      allowfullscreen
      webkitAllowFullScreen
      mozAllowfullscreen
    >
    </iframe>
  </a-modal>
  <a-row class="py-4 px-2 p-md-4" :gutter="[16, 16]">
    <a-col
      span="24"
      class="d-flex justify-content-between align-items-center"
      style="font-size:14px"
    >
      <a-typography-text
        type="danger"
        class="pl-1"
        underline
        role="button"
        @click="showVideo = true"
      >
        Workstation Layout and Camera Placement Guidelines (5 minutes video)
      </a-typography-text>
      <a-button
        type="primary"
        @click="showUploadModal = true"
        id="record-data-new-upload-video-btn"
        ><template #icon>
          <upload-outlined></upload-outlined>
        </template>
        <!-- Upload / Record Video -->
        Upload Video
      </a-button>
    </a-col>
    <a-col span="24">
      <record-video></record-video>
    </a-col>
  </a-row>
</template>
<script>
import RecordVideo from './RecordVideo/RecordVideo.vue';
import UploadVideo from './UploadVideo/UploadVideo.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { showNotification } from '../../../shared/Helpers/showNotification';
import { notification } from 'ant-design-vue';
import { useStore } from 'vuex';

export default {
  components: {
    RecordVideo,
    UploadVideo,
    UploadOutlined
  },
  setup() {
    const showUploadModal = ref(false);
    const activeKey = ref('1');
    const showVideo = ref(false);
    const store = useStore(); // Use useStore inside setup

    onMounted(() => {
      if (store.state.devices.activeDevices.length > 0) return;

      setTimeout(
        showNotification,
        500,
        'Looking for Device(s)',
        'We are looking for active devices in your organization. This will take a few seconds.',
        InfoCircleOutlined,
        '#108ee9'
      );
    });

    onBeforeUnmount(notification.destroy);

    return {
      activeKey,
      showUploadModal,
      showVideo
    };
  }
};
</script>
<style></style>
